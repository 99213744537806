import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import IndexView from "./views/IndexView";
import { useSelector } from "react-redux";
import OutletLogin from "./screens/OutletLogin";
import TimLogin from "./screens/TimLogin";
import ZiggtaLogin from "./screens/ZiggtaLogin";
import ZiggtaLogins from "./views/ZiggtaLogins";
import HostlerLogin from "./views/HostlerLogin";
import DummyLogin from "./views/DummyLogin";
import Hostler from "./screens/Hostler";

function App() {
  const loggedIn = useSelector((state) => state.user.loggedIn);


  return (
    <div>
      {loggedIn ? (
        <Home />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/tmr" element={<IndexView />} />
            <Route path="/outlets/:shopId" element={<OutletLogin />} />
            <Route path="/tim_login" element={<TimLogin />} />
            <Route path='/zz' element={<ZiggtaLogin />}/>
            <Route path="/ziggta" element={<ZiggtaLogins/>}/>
            <Route path="/hosteller" element={<HostlerLogin/>}/>
            <Route path="/hostellers" element={<HostlerLogin/>}/>
            <Route path="/demo" element={<DummyLogin/>}/>
            <Route path="/sdk/:houseId/:roomId"
               element={<Hostler/>}
              />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
