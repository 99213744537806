export const wssurl = 'https://a2.alistetechnologies.com';
export const weburl = 'https://web.alistetechnologies.com';
export const cloudUrl = "https://subscriptioncloud.alistetechnologies.com";
export const HouseIds = {
	taj: "63fd974ec8adbcc8ef9807da",
	tower12: "628b9aa313f8900e04ee500f",
	headquarters: '63132abaae9c2f40e7f573f2',
	timHouse:"6572b8e08d174bc62598f577",
	ziggtaHouse:"65a67f0e53dfff01cfc25b19",
	hostler:'65a8e4210dbad5f4f5880d51',
	hostlers:'65d4774c6f372dbdd1842bc7',
	hq:'60a6a018cc6921f6e1424fcd'
};
export const OwnerNumber = {
	tim:"+918850176155",
	ziggta:'+917990847898',

}

export const LoginTypes = {
	Taj: 0,
	Outlet: 1,
    Tim:2,
	Ziggta:3,
	hostler:4,
	dummy:5,
	sdk:6
}