import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchHouse } from "../redux/actions/house";
import Box from "@mui/material/Box";
import StyledTab from "../components/StyledTab";
import RoomTab from "../components/RoomTab";
import HomeHeader from "../components/HomeHeader";
import StyledTabs from "../components/StyledTabs";
import { SocketEvents } from "../config/socketEvents";
import {
  updateConnectedDevices,
  updateNovaConnected,
  updateSyncConnected,
} from "../redux/actions/connectedDevices";
import { io } from "socket.io-client";
import { LoginTypes, wssurl } from "../config/globals";
import {
  novaPowerUpdate,
  syncSyncStates,
  updateSyncStates,
} from "../redux/actions/socketUpdates";
import SwipeableViews from "react-swipeable-views";
import PayModel from "../components/PayModel";
import { canAccessPage } from "../util/outlet";
class Home extends Component {
  state = {
    selectedRoom: 0,
  };

  socket = null;
  componentDidMount() {
    this.refresh();
  }
  refresh = () => {
    const { house, user } = this.props;

    if (!user || !user.profile) return;
    if (user.loggedIn && house._id !== user.profile.selectedHouse && user.loginType!==LoginTypes.sdk) {
      console.log(user.profile.selectedHouse,house._id);

      this.props.fetchHouse(user.profile.selectedHouse, user.email);
    }
    if (house._id && house._id === user.profile.selectedHouse && !this.socket) {
      let socket = io(wssurl, {
        reconnect: true,
        secure: true,
        query: {
          node_mcu_id: "auto1001",
          device_type: "phone",
          house_access_code: house._id,
          user: user.email,
          source: `webapp_${user.loginType}`,
        },
        transports: ["websocket"],
      });
      socket.on("connect", () => {
        console.log("[websocket] Connected");
        socket.houseId = this.props.house._id;
        socket.user = this.props.user.email;
        socket.emit(SocketEvents.HOUSE_CONNECTION_UPDATES, {
          houseAccessCode: this.props.house._id,
        });
      });
      socket.on("disconnect", () => {
        console.log("[websocket] disconnect");
      });
      socket.on(SocketEvents.SYNC_CONN_UPDATE, this.props.updateSyncConnected);
      socket.on(SocketEvents.NOVA_CONN_UPDATE, this.props.updateNovaConnected);
      socket.on(
        SocketEvents.HOUSE_CONNECTION_UPDATES,
        this.props.updateConnectedDevices
      );
      socket.on(SocketEvents.SYNC_MESSAGE, this.props.updateSyncStates);
      socket.on(SocketEvents.SYNC_SYNC_STATES, this.props.syncSyncStates);
      socket.on(SocketEvents.NOVA_POWER_UPDATE, this.props.novaPowerUpdate);
      socket.connect();
      this.socket = socket;
    }
  };
  componentDidUpdate() {
    this.refresh();
  }

  componentWillUnmount() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }

    document.body.style.overflow = "hidden";
  }

  render() {
    const { roomNames } = this.props;
    const { selectedRoom } = this.state;
    const { loginEntry, user } = this.props;
    let buttonsBlocked = false;
    if (
      user.loginType === LoginTypes.Outlet &&
      !canAccessPage(loginEntry?.date)
    ) {
      buttonsBlocked = true;
    }
    return (
      <div>
        <HomeHeader refresh={this.refresh} />
        <Box
          sx={[
            {
              width: "100%",
            },
            buttonsBlocked
              ? {
                  maxHeight: "100vh",
                  overflow: "hidden",
                  pointerEvents: "none",
                }
              : {},
          ]}
        >
          <Box>
            <StyledTabs
              value={selectedRoom}
              onChange={this.handleChangeRoom}
              aria-label="styled tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {Object.keys(roomNames).map((roomId) => {
                return <StyledTab label={roomNames[roomId]} />;
              })}
            </StyledTabs>
          </Box>
          <Box>
            <SwipeableViews
              index={selectedRoom}
              onChangeIndex={(index) => {
                this.setState({ selectedRoom: index });
              }}
            >
              {Object.keys(roomNames).map((roomId) => {
                return (
                  <RoomTab
                    selectedRoom={roomId}
                    emitSocketEvent={this.emitSocketEvent}
                    label={roomNames[roomId]}
                  />
                );
              })}
            </SwipeableViews>
          </Box>
        </Box>
        <PayModel />
      </div>
    );
  }

  emitSocketEvent = (name, payload) => {
    if (this.socket && this.socket.connected) {
      this.socket.emit(name, payload);
    }
  };

  handleChangeRoom = (e, newRoom) => {
    this.setState({ selectedRoom: newRoom });
  };
}

const mapStateToProps = (state) => ({
  user: state.user,
  house: state.house,
  roomNames: state.rooms.names,
  loginEntry: state.outlet.loginEntry,
});

export default connect(mapStateToProps, {
  fetchHouse,
  updateConnectedDevices,
  updateNovaConnected,
  updateSyncConnected,
  updateSyncStates,
  syncSyncStates,
  novaPowerUpdate,
})(Home);
