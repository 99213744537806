import React from "react";
import { offButtonStyle } from "../../config/style";

const RemoteButton = ({ image, name, onPress }) => {
  return (
    <div>
      <div
        onClick={onPress}
        style={{
          borderRadius: "50%",
          height: "14vw",
          width: "14vw",
          ...offButtonStyle,
          justifyItems: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {image ? (
          <img
            src={image}
            style={{ height: "7vw", width: "7vw", margin: "3.5vw" }}
          />
        ) : (
          <span style={{margin: '3.5vw'}}>{name.slice(0, 2)}</span>
        )}
      </div>
      <div style={{ marginTop: "2vw" }}>
        <span style={{ color: "grey" }}>{name}</span>
      </div>
    </div>
  );
};

export default RemoteButton;
