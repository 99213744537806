import axios from "axios";
import { HouseIds, LoginTypes, OwnerNumber, cloudUrl } from "../../config/globals";
import { TimActions } from "../actionTypes";
import store from "../source";
import { loadUser } from "./user";
import { fetchHouse } from "./house";
import { notifyFailed, notifySuccess } from "../../config/notification";

export const timLoad = (data)=>{
    return {
        type:TimActions.LOAD,
        payload:data
    }
}

export const loginUserPhone = async ( email, password) => {
    const number = email;
    const response = await axios
      .post(`${cloudUrl}/v2/auth/login`, {
        mobile: number,
        password,
        deviceToken:"" ,
      })
      .then(
        result => {
          if (result.status === 200) {
            const {data} = result.data;
         
             store.dispatch(timLoad(data))
             store.dispatch(loadUser({
                email: email,
                profile: data.profile,
                loginType: LoginTypes.Tim,
              }))
            return {success: true, message: 'Sign in successful'};
          } else {
            return {success: false, message: 'Authentication Error'};
          }
        },
        error => {
          let message = error.message;
          if (error.response && error.response.data) {
            message = error.response.data.message;
          }
          return {success: false, message: message};
        },
      )
      .catch(() => {
        return {success: false, message: 'Authentication Error'};
      });
    return response;
  };

  export const updateSelectedHouse = async(houseID, email,name,ownerNumber,loginType) => {
  console.log(ownerNumber,"fffffffownerNumber");
     await axios.post(`${cloudUrl}/api/update/selectedHouse?user=${
           ownerNumber
          }`,
          {
            email: email,
            houseAccessCode: houseID,
          },
        )
        .then(res => {
          const  payload = {
            email: email,
            profile: {
              email: email,
              name: name,
              first_name: name,
              last_name: '',
              selectedHouse: houseID,
            },
            loggedIn: true,
            loginType: loginType,
          }
          store.dispatch(loadUser(payload))
        })
        .catch(error => {
          console.log(error);
          notifyFailed(error.response.data.message)
        });

  };

  export const shareHouse = async(details) => {
       
    return await axios.post(
        `${cloudUrl}/v2/house/add/userWithAccess?user=${details.ownerNumber}&time=${new Date().getTime()}`,
        details,
    )
        .then(
            async(result) => {
                const { data, success, message } = result.data;
                if (success) {
                    notifySuccess(message)
                   await updateSelectedHouse(details.houseId,details.email,details.name,details.ownerNumber,details.loginType)
                   return true
                } else {
                    notifyFailed(message)
                    return false

                }
             
            }
         
        )
        .catch(error => {
            notifyFailed(error.response.data.message)
            return false
        });


};
export const signUpUser = async(detail, setloading) => {
axios.post(`${cloudUrl}/v2/auth/directSignup`, {
    "phone": detail.email,
    "name": detail.name
}).then(async function (res) {
    if (res.data.success) {

         await shareHouse(detail)
       
    }else{
       if(res.data.message==="Number already registered"){
        await shareHouse(detail)
       }else{
        notifyFailed(res.data.message)
       }
    }
    setloading(false)
}).catch(function (err) {
    setloading(false)
})
}