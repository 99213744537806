import { Logout, Refresh } from "@mui/icons-material";
import { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/user";

class HomeHeader extends Component {
  render() {
    return (
      <div
        style={{
          margin: "4vw 9vw 0vw 4vw",
          position: "sticky",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          top: "4vw",
          zIndex: 1,
        }}
      >
        <div>
          <h1>Hi, {this.props.user?.profile?.first_name}</h1>
        </div>

        <div style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
          <div onClick={this.props.refresh} style={{margin: '0vw 4vw'}}>
            <Refresh color={"red"} />
          </div>
          <div onClick={this.props.logoutUser}>
            <Logout color={"red"} sx={{color: 'red'}}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { logoutUser })(HomeHeader);
