import { SignalWifiOff } from "@mui/icons-material";

import { Col, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ButtonRoomStyles } from "../config/style";
import React from "react";
import { useLongPress, LongPressDetectEvents } from "use-long-press";

export default function AnimatedWrapper({ on, online, image, name, onPress, onLongPress }) {
  const theme = useSelector((state) => state.theme);
  
  const callback = React.useCallback(() => {
    onLongPress();
  }, [onLongPress]);

  const bind = useLongPress(callback, {
    onStart: (event, meta) => {},
    onFinish: (event, meta) => {},
    onCancel: (event, meta) => {},
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 300,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  });
  
  const { buttonPercent, buttonMargin } = ButtonRoomStyles;
  return (
    <>
      <Col
        {...bind("")}
        xs={6}
        style={{
          width: `${buttonPercent}vw`,
          height: `${buttonPercent}vw`,
          borderWidth: 2,
          borderColor: "black",
          margin: `${buttonMargin}vw ${buttonMargin}vw`,
          borderRadius: "15%",
          color: "white",
          padding: "5vw 3vw 2vw 5vw",
          background: !on
            ? "white"
            : `linear-gradient(to bottom right, ${theme.gradient[0]}, ${theme.gradient[1]})`,
          boxShadow: !on
            ? `rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px`
            : undefined,
        }}
        onClick={onPress}
       
      >
        <Row>
          <Col>
            <Image src={image} height={`45vw`} width={`45vw`} />
          </Col>
          {online === false && (
            <Col>
              <SignalWifiOff
                style={{
                  color: on ? "white" : "grey",
                  fontSize: "4.5vw",
                  marginLeft: "4vw",
                }}
                fontSize={"inherit"}
              />
            </Col>
          )}
        </Row>
        <Row style={{ marginTop: "10vw" }}>
          <Col>
            <span style={{ color: !on ? "grey" : "white" }}>{name}</span>
          </Col>
        </Row>
      </Col>
    </>
  );
}

// const mapStateToProps = (state) => ({
//   theme: state.theme,
// });
// export default connect(mapStateToProps)(AnimatedWrapper);
