import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string';
import { fetchHouses } from '../redux/actions/house';
import { useDispatch, useSelector } from 'react-redux';
import RoomTab from '../components/RoomTab';
import { io } from "socket.io-client";
import { wssurl } from '../config/globals';
import { updateConnectedDevices, updateNovaConnected, updateSyncConnected } from '../redux/actions/connectedDevices';
import { SocketEvents } from '../config/socketEvents';
import { novaPowerUpdate, syncSyncStates, updateSyncStates } from '../redux/actions/socketUpdates';
import { logoutUser } from '../redux/actions/user';
import { UserActions } from '../redux/actionTypes';


function Hostler() {
    const {houseId,roomId} = useParams()
    const location = useLocation()
    const selectedRoom = useSelector(state=>state.selectedRoom)
    const rooms = useSelector(state=>state.rooms)
    const dispatch= useDispatch()
    const [socket,setSocket] = useState(null)
    console.log(houseId,roomId,"idddd");
    
    console.log(queryString.parse(location.search),"ss");
    console.log(selectedRoom,"room",rooms);
    const emitSocketEvent = (name,payload)=>{
        if (socket && socket.connected) {
            socket.emit(name, payload);
          }
    }
    useEffect(()=>{
       dispatch(({ type: UserActions.LOGOUT}))
      let data = queryString.parse(location.search)
      if(Object.keys(data).length===0){
        return
      }
      if(data.checkin!==undefined && data.checkout!==undefined){
          let start = new Date(data.checkin)
          let end = new Date(data.checkout)
          console.log(start.getTime(),end.getTime());
          let today = new Date()
          if(start.getTime()<today.getTime() && end.getTime()>=today.getTime()){
            
          }else{
            return
          }

      }else{
        alert("Details not found")
        return
      }
      if(houseId!==undefined){
        fetchHouses([houseId],roomId)
        if (socket==null) {
            console.log("come");
            let socket = io(wssurl, {
              reconnect: true,
              secure: true,
              query: {
                node_mcu_id: "auto1001",
                device_type: "phone",
                house_access_code: houseId,
                user: "demo",
                source: `webapp_hostler`,
              },
              transports: ["websocket"],
            });
            socket.on("connect", () => {
              console.log("[websocket] Connected");
              socket.houseId = houseId;
              socket.user = "demo";
              socket.emit(SocketEvents.HOUSE_CONNECTION_UPDATES, {
                houseAccessCode: houseId,
              });
            });
            socket.on("disconnect", () => {
              console.log("[websocket] disconnect");
            });
            socket.on(SocketEvents.SYNC_CONN_UPDATE, updateSyncConnected);
            socket.on(SocketEvents.NOVA_CONN_UPDATE, updateNovaConnected);
            socket.on(
              SocketEvents.HOUSE_CONNECTION_UPDATES,
              updateConnectedDevices
            );
            socket.on(SocketEvents.SYNC_MESSAGE, updateSyncStates);
            socket.on(SocketEvents.SYNC_SYNC_STATES, syncSyncStates);
            socket.on(SocketEvents.NOVA_POWER_UPDATE, novaPowerUpdate);
            socket.connect();
            setSocket(socket)
          }
        
      }
    },[houseId])
  return (
    <div>
                   <RoomTab
                    selectedRoom={roomId}
                    emitSocketEvent={emitSocketEvent}
                    label={"102"}
                  />
    </div>
  )
}

export default Hostler