import axios from "axios";
import { HouseIds, LoginTypes, cloudUrl } from "../../config/globals";
import { HouseActions, RoomActions,SelectedRoomAction } from "../actionTypes";
import store from "../source";
import { loadLinkUser, loadUser } from "./user";

const loadHouse = (
  data = {
    _id: "",
    houseName: "",
    details: "",
  }
) => ({
  type: HouseActions.LOAD,
  payload: data,
});

const loadRooms = (
  data = {
    rooms: [],
  }
) => ({
  type: RoomActions.LOAD,
  payload: data,
});

const loadSelectedRoom = (data)=>{
  return{
     type:SelectedRoomAction.LOAD,
     payload:data
  }
}


export const fetchHouse =
  (houseId = HouseIds.tower12, email = "+917990847898") =>
    (dispatch) => {
      axios
        .get(
          `https://web.alistetechnologies.com/api/fetch/house2/${houseId.trim()}/${email}`
        )
        .then((response) => {
          const { data } = response;
          let details = { ...data };
          delete details.houseName;
          delete details.rooms;
          delete details._id;
          dispatch(
            loadHouse({
              _id: data._id,
              houseName: data.houseName,
              details,
            })
          );
          dispatch(
            loadRooms({
              rooms: data.rooms,
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };


export const fetchHouses = async (houses,roomId) => {

  await axios.post(`${cloudUrl}/app/stanza/getHouseForHouser`, {
    houses: houses
  }).then(res=>{
    console.log(res);
    let details = { ...res.data.data[0] };
          delete details.houseName;
          delete details.rooms;
          delete details._id;
          store.dispatch(
            loadHouse({
              _id: res.data.data[0]._id,
              houseName: res.data.data[0].houseName,
              details,
            })
          );
          store.dispatch(
            loadLinkUser({
              email: "hostler",
              profile: {},
              loginType: LoginTypes.sdk,
            })
          )
    for(let r of res.data.data[0].rooms){
      
      if(r._id!==undefined && r._id===roomId){
        console.log(r,"roomFound");
        store.dispatch(loadSelectedRoom(r))
        store.dispatch(
          loadRooms({
            rooms: [r],
          })
        );
      }
    }
  }).catch(err=>{
    console.log(err);
  })
}
