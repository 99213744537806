import { LoginTypes } from "../../config/globals";
import {
	UserActions
} from "../actionTypes";

const initialState = {
	email: '',
	profile: {
		email: '',
		name: '',
		first_name: '',
		last_name: '',
		selectedHouse: '',
	},
	loggedIn: false,
	loginType: LoginTypes.Taj,
}



const user = (state = initialState, action = {
	type: '',
	payload: {}
}) => {
	const {
		type,
		payload
	} = action;
	switch (type) {
		case UserActions.LOAD:
			return {
				...state,
				email: payload.email,
				profile: payload.profile,
				loggedIn: true,
				loginType: payload.loginType,
			};
		case UserActions.LOAD_LINK:
			return {
				...state,
				email: payload.email,
				profile: payload.profile,
				loggedIn: false,
				loginType: payload.loginType,
			}

		case UserActions.LOGOUT: 
			return initialState;
			
		default:
			return state;
	}
}

export default user;