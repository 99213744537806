import React, { Component } from "react";
import { connect } from "react-redux";
import {
  generateNovaCommand,
  RemoteButtons,
  RemoteTypeImageMap,
  RemoteTypeMap,
} from "../config/nova";
import { SocketEvents } from "../config/socketEvents";
import { novaPowerUpdate } from "../redux/actions/socketUpdates";
import AnimatedWrapper from "./AnimatedWrapper";
import CustomRemote from "./Remotes/CustomRemote";
import TVRemote from "./Remotes/TVRemote";

class NovaButton extends Component {
  state = {
    showRemote: false,
  };

  render() {
    const { remote, connectedNovas } = this.props;
    const on = Number(remote.power_state) !== 0;
    const online = connectedNovas.includes(remote.deviceId);
    return (
      <>
        <AnimatedWrapper
          on={on}
          name={remote.name}
          image={
            on
              ? RemoteTypeImageMap[remote.remoteType].on
              : RemoteTypeImageMap[remote.remoteType].off
          }
          online={online}
          onPress={this.handleButtonClick}
          onLongPress={this.handleLongPress}
        />
        {remote.remoteType === RemoteTypeMap.TV &&
          this.state.showRemote === true && (
            <TVRemote
              showModal={this.state.showRemote}
              closeModal={this.hideRemote}
              remote={remote}
              sendCode={this.sendCode}
              powerClicked={this.handleButtonClick}
            />
          )}
        {remote.remoteType === RemoteTypeMap.Custom &&
          this.state.showRemote === true && (
            <CustomRemote
              showModal={this.state.showRemote}
              closeModal={this.hideRemote}
              remote={remote}
              sendCode={this.sendCode}
              powerClicked={this.handleButtonClick}
            />
          )}
      </>
    );
  }

  hideRemote = () => {
    this.setState({ showRemote: false });
  };

  handleLongPress = () => {
    this.setState({ showRemote: true });
  };

  handleButtonClick = () => {
    const { connectedNovas, remote } = this.props;
    if (!connectedNovas.includes(remote.deviceId)) {
      console.log("[buttonClick] ir is offline", remote.deviceId, remote._id);
    }
    let state = remote.power_state === 0 ? 1 : 0;
    let powerButtonIndex = remote.buttons.findIndex(
      (button) => button.type === RemoteButtons.power.value
    );
    let powerOnIndex = remote.buttons.findIndex(
      (button) => button.type === RemoteButtons.power_on.value
    );
    let powerOffIndex = remote.buttons.findIndex(
      (button) => button.type === RemoteButtons.power_off.value
    );
    let button;
    if (
      powerOffIndex === -1 &&
      powerOnIndex === -1 &&
      powerButtonIndex === -1
    ) {
      return;
    } else if (powerButtonIndex !== -1) {
      button = remote.buttons[powerButtonIndex];
    } else if (powerOffIndex === -1 || powerOnIndex === -1) {
      button =
        powerOffIndex === -1
          ? remote.buttons[powerOnIndex]
          : remote.buttons[powerOffIndex];
    } else {
      button =
        remote.power_state === 0
          ? remote.buttons[powerOnIndex]
          : remote.buttons[powerOffIndex];
    }
    this.sendCode({
      ...button,
      learned:
        button.code !== undefined && button.code.decode_type !== undefined,
    });
    this.props.novaPowerUpdate({
      deviceId: remote.deviceId,
      remote_id: remote._id,
      power_state: state,
    });
  };

  sendCode = (button) => {
    const { remote, emitSocketEvent } = this.props;
    if (button.learned) {
      let state = remote.power_state;
      if (isNaN(state)) {
        state = 0;
      } else {
        state = state === 0 ? 1 : 0;
      }
      if (
        button.type === RemoteButtons.power_on.value ||
        button.type === RemoteButtons.power_off.value ||
        button.type === RemoteButtons.power.value
      ) {
        emitSocketEvent(SocketEvents.NOVA_CONTROL, {
          deviceId: remote.deviceId,
          payload: generateNovaCommand(button.code, button.id),
          power_button: true,
          power_state: state,
          remote_id: remote._id,
        });
        this.props.novaPowerUpdate({
          deviceId: remote.deviceId,
          remote_id: remote._id,
          power_state: state,
        });
      } else {
        emitSocketEvent(SocketEvents.NOVA_CONTROL, {
          deviceId: remote.deviceId,
          payload: generateNovaCommand(button.code, button.id),
        });
      }
    }
  };
}

const mapStateToProps = (state) => ({
  connectedNovas: state.connectedDevices.novas,
  user: state.user,
});

export default connect(mapStateToProps, { novaPowerUpdate })(NovaButton);
