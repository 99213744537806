import { Component } from "react";
import { connect } from "react-redux";
import { SocketEvents } from "../config/socketEvents";
import { DeviceTypeImageMap } from "../config/typeMaps";
import { updateSyncStates } from "../redux/actions/socketUpdates";
import AnimatedWrapper from "./AnimatedWrapper";
import axios from "axios";
import { LoginTypes } from "../config/globals";

class SyncButton extends Component {

  render() {
    const { appliance, connectedSyncs, user } = this.props;
   
    const on = Number(appliance.state) !== 0;
    const online = connectedSyncs.includes(appliance.deviceId) || user.loginType===LoginTypes.Tim || user.loginType===LoginTypes.sdk;
    return (
      <>
        <AnimatedWrapper
          on={on}
          name={appliance.switchName}
          image={
            on
              ? DeviceTypeImageMap[appliance.deviceType]?.on
              : DeviceTypeImageMap[appliance.deviceType]?.off
          }
          online={online}
          onPress={this.handleButtonClick}
          onLongPress={this.handleLongPress}
        />
      </>
    );
  }

  handleLongPress = () => {
    this.handleButtonClick();
  }
  callUpdate = (deviceId, switchId, command,email) => {
    var c  = 100
    if (command == "true") {
      c = 0
    }
    console.log(deviceId,switchId,command,c,email,"call");
    axios
      .post(
        `https://a3.alistetechnologies.com/v3/device/control`,
        {
          deviceId,
          switchId,
          command : c,
          controllerType:"Taj Portal",
          controllerId:email,
          controllerDetails:{}
        },
      )
      .then(res => {
        return
      })
      .catch(error => {
        return
      });
  
};
  handleButtonClick = () => {
    const { connectedSyncs, appliance } = this.props;
    if (!connectedSyncs.includes(appliance.deviceId)) {
      console.log(
        "[buttonClick] device is offline",
        appliance.deviceId,
        appliance.switchId
      );
    }
    let command = Number(appliance.state) === 0 ? 100 : 0;
    let { email, profile } = this.props.user;
    let username = profile.name
      ? profile.name
      : `${profile.first_name} ${profile.last_name}`;
    let message = {
      deviceId: appliance.deviceId,
      switchId: appliance.switchId,
      command,
      device_type: "phone",
      user: email,
      username,
      id: String(new Date().getTime()).slice(5, 13),
    };
    this.props.updateSyncStates({
      deviceId: appliance.deviceId,
      switchId: appliance.switchId,
      state: command,
    });
    console.log(command,"call");
    this.callUpdate(appliance.deviceId,appliance.switchId,command===0?"true":"false",email)
    this.props.emitSocketEvent(SocketEvents.SYNC_MESSAGE, message);
    
  };
}

const mapStateToProps = (state) => ({
  connectedSyncs: state.connectedDevices.syncs,
  user: state.user,

});
export default connect(mapStateToProps, { updateSyncStates })(SyncButton);
