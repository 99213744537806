import "../styles/timLogin.css";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import logo from '../assets/ziggta/ziggta.jpeg'
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { loginUserPhone, signUpUser } from "../redux/actions/tim";
import axios from "axios";
import { HouseIds, LoginTypes, OwnerNumber, cloudUrl } from "../config/globals";
import Multiselect from "multiselect-react-dropdown";
import { notifyFailed } from "../config/notification";
import { Toaster } from 'react-hot-toast'
import { loadUser, loginZiggtaUser } from "../redux/actions/user";
import { useDispatch } from "react-redux";
// import Strings from "../../globals/strings";

const ZiggtaLogin = () => {
  const [username, setUsername] = useState("");
  const [registered, setRegistered] = useState(true)
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState("")
  const [roomData, setRoomData] = useState([])
  const [originalRoomData, setOriginalRoomData] = useState([])
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch()
  useEffect(() => {
    // dispatch(loadUser({
    //   email: 'Dummy',

    //   profile: {
    //     email: 'Dummy',
    //     name: 'Dummy',
    //     first_name: 'Dummy',
    //     last_name: '',
    //     selectedHouse: HouseIds.ziggtaHouse,
    //   },
    //   loggedIn: true,
    //   loginType: LoginTypes.Ziggta,
    // }))
    loginZiggtaUser("Dummy","+91123456789", new Date())
  },[])
  const isValidNumber = (number) => {
    console.log(number, "number")
    let num = number;
    if (String(number).startsWith("+")) {
      // if the number starts with a plus accept it as is
      if (!String(number).startsWith('+91')) return false;
      num = String(number).replace('+91', '');

    }
    // the number does not start with + assume its an indian number and process accordingly
    num = String(num).replace(/\D/g, "");
    while (num.startsWith('0')) {
      num = num.slice(1, num.length)
    }
    return num.length === 10;
  };
  const handleSubmitLogin = async () => {
    setLoading(true);
    const num = isValidNumber(username)
    if (!num) {
      notifyFailed("Phone Number is not valid")
      setLoading(false)
      return
    }


    const cleanNumber = (num = '') => {
      try {
        let cn = String(num).replace(/\D/g, ''); // replace non digits
        if (cn.startsWith('0')) {
          // the number is starting with 0, remove it
          cn = cn.slice(1, cn.length);
        }
        if (cn.length > 10) {
          cn = cn.slice(cn.length - 10, cn.length);
        }
        return `+91${cn}`;
      } catch (_) {
        return num;
      }
    };

    const response = await loginUserPhone(cleanNumber(username), username);
    if (!response.success) {
      notifyFailed(response.message);
    }
    setLoading(false);

  };

  const handleSubmit = (event) => {
    setLoading(true)
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    let roomId = []
    let detail = {
      name: data.get('name'),
      phoneNumber: data.get('phoneNumber'),
      date: new Date()
    }
    const num = isValidNumber(detail.phoneNumber)
    if (!num) {
      setMessage("Phone Number is not valid")
      return
    }


    const cleanNumber = (num = '') => {
      try {
        let cn = String(num).replace(/\D/g, ''); // replace non digits
        if (cn.startsWith('0')) {
          // the number is starting with 0, remove it
          cn = cn.slice(1, cn.length);
        }
        if (cn.length > 10) {
          cn = cn.slice(cn.length - 10, cn.length);
        }
        return `+91${cn}`;
      } catch (_) {
        return num;
      }
    };
    detail.phoneNumber = cleanNumber(detail.phoneNumber)

    let d = {
      "email": detail.phoneNumber,
      "name": detail.name,
      "houseId": HouseIds.ziggtaHouse,
      "isMaster": false,
      "owner": false,
      "roomIds": roomId,
      "timed": true,
      "validtill": detail.date,
      'ownerNumber': OwnerNumber.ziggta,
      'loginType': LoginTypes.Ziggta
    }
    console.log(d, "detal");
    signUpUser(d, setLoading)
  }

  return (

    <div style={{ backgroundColor: "rgb(249, 249, 251)", height: "100vh" }}>

      {
        registered ? (
          <div className="main-login-container">

            <div className="login-container">
              <Modal.Body>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  <img src={logo} width="20%" style={{ marginBottom: "2%" }} alt={'Aliste Logo'} />
                </div>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="number" placeholder="Mobile Number" name='phoneNumber' required onChange={(e) => setUsername(e.target.value)} />
                </Form.Group>

                <Button
                  variant="primary"
                  className="mr-2"
                  onClick={()=>loginZiggtaUser("Dummy","+91123456789", new Date())}
                  disabled={loading}
                >
                  Login
                </Button>

              </Modal.Body>
            </div>
            <h2>Or</h2>
            <div className="login-container">
              <Button

                onClick={() => setRegistered(false)}
              >
                Sign Up
              </Button>
            </div>
          </div>
        ) : (
          <Modal
            show={true} style={{ backgroundColor: "rgb(249, 249, 251)" }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >

            <Modal.Body>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={logo} width="25%" style={{ marginBottom: "2%" }} alt={'Aliste Logo'} />
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Guest Name</Form.Label>
                  <Form.Control type="text" placeholder="Name" name='name' required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Guest Phone Number</Form.Label>
                  <Form.Control type="text" placeholder="Mobile Number" name='phoneNumber' required />
                </Form.Group>


                <Button
                  variant="primary"
                  type='submit'
                  disabled={loading}
                >
                  Sign Up
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        )
      }

      <Toaster />
    </div>
  );
};


export default ZiggtaLogin;
