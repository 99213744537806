const initialState = {
	gradient: ['#677BF3', '#2C54DF'],
  primaryColor: '#5276f5',
}

const theme = (state = initialState, action) => {
	const {type} = action;
	switch (type) {
		default:
			return state;
	}
}

export default theme;
