import React, { Component } from "react";
import Modal from "react-modal";
import money from "../assets/PayModel/money.jpg";
import { connect } from "react-redux";
import { outletPaymentLink } from "../redux/actions/outlet";
import { LoginTypes } from "../config/globals";
import { canAccessPage } from "../util/outlet";
const customStyles = {
  content: {
    width: "100%",
    position: "absolute",
    bottom: "0px",
    top: "50vh",
    height: "100vh",
    left: "0",
    borderRadius: "18px 18px 0px 0px",
    padding: "5%",
    paddingTop: "20px",
    zIndex: 10,
    overflow: "hidden",
  },
};
const styles = {
  heading: {
    fontWeight: 700,
    fontSize: "22px",
    textAlign: "center",
  },
};
class PayModel extends Component {
  state = {
    rerender: 0,
  };
  fetching = false;
  countdownTimer = null;
  handleBuyNow = async (event) => {
    event.preventDefault();
    if (this.fetching) return;
    this.fetching = true;
    const { outlet, loginEntry } = this.props;
    const details = {
      outletId: outlet.outletId,
      name: loginEntry.name,
      phone: loginEntry.phone,
      email: loginEntry.email,
      city: loginEntry.city,
    };
    const response = await outletPaymentLink(details);
    if (response.success) {
      const { temporarySubscription } = response.data;
      window.location = temporarySubscription.partialPaymentDetails.short_url;
    }
    this.fetching = false;
  };

  componentWillUnmount() {
    if (this.countdownTimer) {
      clearTimeout(this.countdownTimer);
    }
  }

  setCountdownTimer = () => {
    if (this.countdownTimer) {
      clearTimeout(this.countdownTimer);
    }
    if (canAccessPage(this.props.loginEntry.date)) {
      const { loginEntry } = this.props;
      this.countdownTimer = setTimeout(() => {
        this.setState({ rerender: this.state.rerender + 1 });
      }, new Date(loginEntry.date).getTime() + 5 * 60 * 1000 - new Date().getTime());
    }
  };

  componentDidMount() {
    const { loginType } = this.props;
    if (loginType === LoginTypes.Outlet) {
      this.setCountdownTimer();
    }
  }

  render() {
    const { loginType, loginEntry } = this.props;
    if (loginType !== LoginTypes.Outlet) return null;
    if (canAccessPage(loginEntry.date)) return null;
    return (
      <div
        style={{
          height: "100vh",
          position: "fixed",
          width: "100vw",
          top: "0",
          left: "0",
          margin: 0,
          overflow: "hidden",
        }}
      >
        <Modal isOpen={true} style={customStyles} contentLabel="Example Modal">
          <div style={styles.heading}>Liked the experience?</div>
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "5px",
              textAlign: "center",
              color: "#858585",
            }}
          >
            Your own smart home is just one click away. Book it now by clicking
            the button below.
          </div>
          <div>
            <img
              src={money}
              style={{ width: "90%", marginLeft: "5%", marginRight: "5%" }}
            />
          </div>
          <button
            style={{
              backgroundColor: "#6D67E4",
              fontSize: "18px",
              width: "90%",
              margin: "0 5%",
              marginTop: "15px",
              border: "none",
              borderRadius: "12px",
              fontWeight: 600,
              height: "40px",
              color: "white",
            }}
            onClick={this.handleBuyNow}
          >
            Buy Now
          </button>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginType: state.user.loginType,
  outlet: state.outlet.outlet,
  loginEntry: state.outlet.loginEntry,
});

export default connect(mapStateToProps)(PayModel);
