import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { ButtonRoomStyles } from "../config/style";
import { DeviceTypes } from "../config/typeMaps";
import NovaButton from "./NovaButton";
import SyncButton from "./SyncButton";

class RoomTab extends Component {
  render() {
    const { selectedRoom, rooms } = this.props;
    const roomAppliances = rooms[selectedRoom];

    return (
      <Row
        style={{
          margin: `${ButtonRoomStyles.rowMargin}vw ${ButtonRoomStyles.rowMargin}vw`,
        }}
        className={"g-0"}
      >
        {roomAppliances?.map((appliance, index) => {
          if (appliance.type === DeviceTypes.SYNC) {
            return (
              <SyncButton
                appliance={appliance}
                emitSocketEvent={this.props.emitSocketEvent}
              />
            ); 
          } else if (appliance.type === DeviceTypes.NOVA) {
            return (
              <NovaButton
                remote={appliance}
                emitSocketEvent={this.props.emitSocketEvent}
              />
            ); 
          }
          return null;
        })}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  rooms: state.rooms.iterated,
});

export default connect(mapStateToProps)(RoomTab);
